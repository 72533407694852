export enum Modules {
  DASHBOARD = "DASHBOARD",
  USER = "USER",
  TRANSACTION = "TRANSACTION",
  STEAM = "STEAM",
  COMMON = "COMMON",
  CUSTOMER = "CUSTOMER",
  DEVICE = "DEVICE",
  MERCHANT = "MERCHANT",
  BATCHES = "BATCHES",
  ASSET = "ASSET",
  PAYMENT = "PAYMENT",
  MERCHANT_SETTINGS= "MERCHANT_SETTINGS",
  CUSTOMERCONNECT = "CUSTOMERCONNECT",
  TPN = "TPN",
  SETTINGS="SETTINGS",
  MY_TICKETS="MY_TICKETS",
  ISO_SETTINGS="ISO_SETTINGS",
  CLOUD_POS="CLOUD_POS",
  CONSUMER="CONSUMER",
  AUDITLOGS="AUDITLOGS",
  NOTES="NOTES",
  TROUBLESHOOT="TROUBLESHOOT",
  BILLING="BILLING",
  HOST_INVENTORY ="HOST_INVENTORY",
  PROCESSOR="PROCESSOR",
  RISK_MANAGEMENT = "RISK_MANAGEMENT",
  DEVELOPER_CENTER = "DEVELOPER_CENTER",
}
