import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { LayoutModule } from "./views/layout/layout.module";
import { AuthGuard } from "./core/guard/auth.guard";
import { AppComponent } from "./app.component";
import { ErrorPageComponent } from "./views/pages/error-page/error-page.component";
import { HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CustomDateParserFormatterService } from "./providers/custom-date-parser-formatter.service";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angularx-qrcode';
// search module
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { HttpInterceptorInterceptor, DEFAULT_TIMEOUT } from "./interceptor/http-interceptor.interceptor";
import { PasswordResetComponent } from "../app/views/pages/auth/password-reset/password-reset.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { ToastModule } from "primeng/toast";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { AccordionModule } from "primeng/accordion";
import { UploadImageDirective } from "./core/directive/upload-image.directive";
import { ViewRecepitComponent } from '../app/views/pages/transaction-management/view-recepit/view-recepit.component';
import { SharedModule } from "./shared/shared.module";
import {ConnectionServiceModule} from 'ng-connection-service';
import { CookieModule } from 'ngx-cookie';
import { datePipeModule } from "./shared/pipe/datePipe.module";
import { GlobalErrorHandler } from "../app/providers/error-handler.service";
import { BillingInvoiceComponent } from './views/pages/billingInvoice/billing-invoice/billing-invoice.component';
import { AddHostInventoryComponent } from './views/pages/host-inventory/add-host-inventory/add-host-inventory.component';
import { ListHostInventoryComponent } from "./views/pages/host-inventory/list-host-inventory/list-host-inventory.component";
import { BuilkEditTidComponent } from './views/pages/host-inventory/builk-edit-tid/builk-edit-tid.component';
import { ProcessorListComponent } from "./views/pages/processor-management/processor-list/processor-list.component";
import { AddProcessorComponent } from "./views/pages/processor-management/add-processor/add-processor.component";
import { ChipsModule } from "primeng/chips";
import {InputTextModule} from 'primeng/inputtext';
import { SelectButtonModule } from "primeng/selectbutton";
import { FilterHostInventoryManagementComponent } from './views/pages/host-inventory/filter-host-inventory-management/filter-host-inventory-management.component';
import { FeahterIconModule } from 'src/app/core/feather-icon/feather-icon.module';
import { BulkUploadHostInventoryComponent } from './views/pages/host-inventory/bulk-upload-host-inventory/bulk-upload-host-inventory.component';
import { BulkStatusUpdateComponent } from './views/pages/host-inventory/bulk-status-update/bulk-status-update.component';



const maskConfig: Partial<IConfig> = {
  validation: false,
};
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({

  declarations: [
    AppComponent,
    ErrorPageComponent,
    PasswordResetComponent,
    UploadImageDirective,
    ViewRecepitComponent,
    BillingInvoiceComponent,
    AddHostInventoryComponent,
    ListHostInventoryComponent,
    BuilkEditTidComponent,
    ProcessorListComponent,
    AddProcessorComponent,
    FilterHostInventoryManagementComponent,
    BulkUploadHostInventoryComponent,
    BulkStatusUpdateComponent
  ],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ConnectionServiceModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    SweetAlert2Module,
    DialogModule,
    ToastModule,
    HttpClientModule,
    ButtonModule,
    NgxIntlTelInputModule,
    AccordionModule,
    SharedModule,
    NgSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    datePipeModule.forRoot(),
    ChipsModule,
    InputTextModule,
    SelectButtonModule,
    FeahterIconModule,
    QRCodeModule

  ],
  providers: [

    AuthGuard,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        // languages: {
        //   xml: () => import('highlight.js/lib/languages/xml'),
        //   typescript: () => import('highlight.js/lib/languages/typescript'),
        //   scss: () => import('highlight.js/lib/languages/scss'),
        // }
      },
    },
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorInterceptor,
      multi: true,
    },
    { provide: DEFAULT_TIMEOUT, useValue: 5 * 60 * 1000 },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
