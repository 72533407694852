export enum Privileges {
  VIEW_DASHBOARD="VIEW_DASHBOARD",
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  PRINT = "PRINT",
  EXPORT = "EXPORT",
  ADD_USER = "ADD_USER",
  EXPORT_USER = "EXPORT_USER",
  VIEW_USER = "VIEW_USER",
  EDIT_USER = "EDIT_USER",
  RESENT_ACTIVATION_LINK = "RESENT_ACTIVATION_LINK",
  RESET_PASSWORD = "RESET_PASSWORD",
  PRINT_USER = "PRINT_USER",
  DELETE_USER = "DELETE_USER",
  VIEW_CUSTOMER = "VIEW_CUSTOMER",
  ADD_ISO =  "ADD_ISO",
  ADD_SUB_ISO = "ADD_SUB_ISO",
  EDIT_CUSTOMER = "EDIT_CUSTOMER",
  DELETE_CUSTOMER = "DELETE_CUSTOMER",
  EXPORT_CUSTOMER = "EXPORT_CUSTOMER",
  PRINT_CUSTOMER = "PRINT_CUSTOMER",
  VIEW_APPLICATION = "VIEW_APPLICATION",
  ADD_APPLICATION = "ADD_APPLICATION",
  VIEW_DEVICE = "VIEW_DEVICE",
  ADD_ASSET = "ADD_ASSET",
  EDIT_ASSET = "EDIT_ASSET",
  VIEW_ASSET = "VIEW_ASSET",
  DELETE_ASSET = "DELETE_ASSET",
  VIEW_DOWNLOAD_LOG = "VIEW_DOWNLOAD_LOG",
  VIEW_MERCHANT = "VIEW_MERCHANT",
  VIRTUAL_PAYMENT = "VIRTUAL_PAYMENT",
  VIEW_DEVICE_ALERT = "VIEW_DEVICE_ALERT",
  ADD_MERCHANT = "ADD_MERCHANT",
  ADD_PROMOTIONAL_SETTING = "ADD_PROMOTIONAL_SETTING",
  EDIT_PROMOTIONAL_SETTING = "EDIT_PROMOTIONAL_SETTING",
  VIEW_BATCHES = "VIEW_BATCHES",
  MAKE_SETTLEMENT = "MAKE_SETTLEMENT",
  ADD_TPN = "ADD_TPN",
  HELP_DESK="HELP_DESK",
  VIEW_TICKET="VIEW_TICKET",
  EDIT_ACTIONS="EDIT_ACTIONS",
  DELETE_MERCHANT="DELETE_MERCHANT",
  ADD_CUSTOMER="ADD_CUSTOMER",
  ADD_STORE="ADD_STORE",
  EDIT_STORE="EDIT_STORE",
  EDIT_TPN="EDIT_TPN",
  EXPORT_MERCHANT="EXPORT_MERCHANT",
  PRINT_MERCHANT="PRINT_MERCHANT",
  EDIT_MERCHANT="EDIT_MERCHANT",
  DELETE_STORE="DELETE_STORE",
  DELETE_TPN="DELETE_TPN",
  ASSIGN_BUILD="ASSIGN_BUILD",
  EDIT_PARAM="EDIT_PARAM",
  EXPORT_TPN="EXPORT_TPN",
  VIEW_ASSETS="VIEW_ASSETS",
  EDIT_ASSETS="EDIT_ASSETS",
  DELETE_ASSETS="DELETE_ASSETS",
  ADD_ASSETS="ADD_ASSETS",
  ADD_DEVICE_MODELS="ADD_DEVICE_MODELS",
  EDIT_DEVICE_MODELS="EDIT_DEVICE_MODELS",
  DELETE_DEVICE_MODELS="DELETE_DEVICE_MODELS",
  VIEW_TRANSACTION="VIEW_TRANSACTION",
  VIEW_RECEIPT="VIEW_RECEIPT",
  REFUND_TRANSACTION="REFUND_TRANSACTION",
  TICKET_TRANSACTION="TICKET_TRANSACTION",
  EXPORT_TRANSACTION="EXPORT_TRANSACTION",
  SETTLE_BATCH="SETTLE_BATCH",
  EXPORT_BATCH="EXPORT_BATCH",
  TIP_ADJUST="TIP_ADJUST",
  INVOICE_LIST="INVOICE_LIST",
  UPLOAD_INVOICE="UPLOAD_INVOICE",
  ADD_INVOICE="ADD_INVOICE",
  EDIT_INVOICE="EDIT_INVOICE",
  VIEW_INVOICE_LIST="VIEW_INVOICE_LIST",
  VIEW_CONSUMER="VIEW_CONSUMER",
  ADD_CONSUMER="ADD_CONSUMER",
  EDIT_CONSUMER="EDIT_CONSUMER",
  DELETE_CONSUMER="DELETE_CONSUMER",
  ADD_CARD="ADD_CARD",
  EDIT_CARD="EDIT_CARD",
  DELETE_CARD="DELETE_CARD",
  EXPORT_CONSUMER="EXPORT_CONSUMER",
  SETTLE_EMAIL_ALERT="SETTLE_EMAIL_ALERT",
  MERCHANT_UPLOAD_LOGO = "MERCHANT_UPLOAD_LOGO",
  VIEW_AUDITLOGS="VIEW_AUDITLOGS",
  VIEW_AUDIT_LOGS="VIEW_AUDIT_LOGS",
  ADD_NOTES="ADD_NOTES",
  VIEW_NOTES="VIEW_NOTES",
  SPIN_PROXY="SPIN_PROXY",
  ADD_CONTRACT="ADD_CONTRACT",
  EDIT_CONTRACT="EDIT_CONTRACT",
  VIEW_INVOICE="VIEW_INVOICE",
  GENERATE_BILLING="GENERATE_BILLING",
  ADD_HOST_INVENTORY="ADD_HOST_INVENTORY",
  VIEW_HOST_INVENTORY="VIEW_HOST_INVENTORY",
  EDIT_HOST_INVENTORY="EDIT_HOST_INVENTORY",
  VIEW_PROCESSOR_LIST="VIEW_PROCESSOR_LIST",
  ADD_PROCESSOR="ADD_PROCESSOR",
  EDIT_PROCESSOR="EDIT_PROCESSOR",
  DELETE_POS_PARAM = "DELETE_POS_PARAM",
  VIEW_RISK_DETAILS = "VIEW_RISK_DETAILS",
  EDIT_RISK_DETAILS = "EDIT_RISK_DETAILS",
  VIEW_RISK_TEMPLATES = "VIEW_RISK_TEMPLATES",
  EDIT_RISK_TEMPLATES = "EDIT_RISK_TEMPLATES",
  DELETE_RISK_TEMPLATES = "DELETE_RISK_TEMPLATES",
  EXTERNAL_DOCUMENT = "EXTERNAL_DOCUMENT"
}
